import React from "react";

import hero from '../images/hero-laila-4.jpg';
import RotatingText from "./RotatingText";
import heroLaila from '../images/hero-laila-gruen-2.jpg';


const CleanHero = ({
    // path = "https://www.manfrottoschoolofxcellence.com/wp-content/uploads/2017/08/Cover_dutton_portrait_01-1440x700.jpg"
    // "https://images.squarespace-cdn.com/content/v1/5f9abeb9339c5733734c0189/1603998853803-0HFPJ7ZVJ5CSAZCGFZ7J/Portrait+Laila+Bosco"
    path = hero
}) => {
    return (
        <div className="relative bg-white">

            <div className="relative md:w-full mx-auto pt-24 px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                {/* <h1
                    className="block text-black text-left text-3xl  md:text-8xl"
                >
                    Laila schreibt.
                </h1> */}
                <RotatingText />
            </div>
        </div>
    )
}

export default CleanHero;