import '../App.css';
import React from "react";
import { useRef } from "react";

import Seo from '../components/seo';
import ProjektePreview from '../components/ProjektePreview';
import ImageHero from '../components/ImageHero';

import lailaUeberMich from '../images/laila-ueber-mich-teaser.jpg';

import angebot1 from "../images/laila-schreibt-angebot-1.jpg";
import angebot2 from "../images/laila-schreibt-angebot-2.jpg";
import angebot3 from "../images/laila-schreibt-angebot-3.jpg";
import CleanHero from '../components/CleanHero';


const angebote = [
  {
    title: "Texten.",
    img: angebot1

  }, {
    title: "Geschichten jagen.",
    img: angebot2
  },
  {
    title: "Wortwurzeln zerpflücken.",
    img: angebot3
  },
  {
    title: "Fotografieren.",
    img: angebot1

  },
  {
    title: "Social Media deichseln.",
    img: angebot2

  },
  {
    title: "Blogbeiträge verfassen.",
    img: angebot2

  },
  {
    title: "Filmen.",
    img: angebot3

  },
  {
    title: "Gute Laune versprühen.",
    img: angebot1

  }
];


const IndexPage = () => {

  const ref = useRef(null);

  return (
    <div>
      <Seo title='Laila schreibt' />
      <div className='relative md:min-h-screen'>
        <CleanHero />
        <div className="py-16 lg:py-24">
          <div className="content-slim">
            <div className='text-3xl mx-auto md:mx-0 md:text-5xl max-w-4xl leading-snug tracking-wide'>
              <p
                className=''
              >
                Die Welt besteht aus Geschichten, nicht Atomen.
                Und diese verpacke ich dir hübsch in <span className="underline">Text</span>, <span className="underline">Bild</span> und <span className="underline">Video</span>.
              </p>
            </div>
          </div>
        </div>
        <div
          className='lg:absolute lg:left-0 lg:right-0 lg:bottom-[12rem]'
        >
          <div className='relative hidden md:block mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl'>
            <a href="#preview" class="" title="Mehr zu mir">
              <h2
                className=' text-3xl'
              >
                Was bei mir so abgeht ↓
              </h2>
            </a>
          </div>
        </div>
      </div>

      <ProjektePreview />

      <div className="pt-32 pb-60 relative">
        <div className="content-slim">

          <div className='text-3xl leading-snug tracking-wide text-light'>
            <div
              className='flex items-center gap-24'>
              <h2
                className='text-3xl md:text-5xl leading-snug tracking-wide '

              >Like what you see? </h2>
            </div>

            <ul
              className='flex gap-8 flex-wrap pt-6 flex-col md:flex-row'
            >
              <h3
                className='text-3xl leading-snug tracking-wide '
              >
                Mein Angebot:
              </h3>
              {angebote.map(({
                title,
                img
              }, index) => {
                return (
                  <li
                    key={index}
                    className='group flex shrink-0 cursor-pointer'
                  >
                    <h2>{title}</h2>
                  </li>
                )
              })}
            </ul>
          </div>

        </div>
      </div>

      <div
        className="content-grid md:grid-cols-2 pb-8 bg-black"
      >

        <div
          className=""
        // px-4 sm:px-6 lg:px-8
        >

          <img src={lailaUeberMich}
            className="w-full h-auto md:py-24" />

        </div>

        <div
          className="content-slim base-pr flex flex-col justify-center">
          <span className="text-white pb-6 font-heading text-xl">
            Über mich
          </span>
          <h3
            className='text-3xl md:text-6xl text-white leading-snug tracking-wide max-w-3xl'
          >
            Und wer ist diese Laila genau und warum schreibt die?!
          </h3>
          <a
            className="group relative border-white border-2 mt-12 text-white px-8 py-4 font-heading flex mr-auto transition-all hover:bg-white hover:text-black"
            href="/ueber-mich"
            title="Über Laila"
          >
            Do chasch guene
            <span className='absolute right-1 opacity-0 duration-700 delay-500 -translate-y-10 group-hover:opacity-100 group-hover:translate-y-0'>🔥</span>
          </a>
        </div>

      </div>

    </div >
  )
}

export default IndexPage;
